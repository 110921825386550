<template>
	<div>
		<v-data-table
			:loading="dataLoading"
			:loadingText="loadingText"
			:headers="filteredHeaders"
			:itemClass="itemClass"
			:options.sync="__options"
			@click:row="
				(item, _, e) => {
					if (level < 3) $emit('openDetail', item, filter, e);
				}
			"
			:items="data">
			<template #progress>
				<v-progress-linear
					:value="progress"
					color="primary"></v-progress-linear>
			</template>
			<template #top="{ pagination }">
				<v-data-footer
					class="anton"
					:options.sync="__options"
					:pagination="pagination"></v-data-footer>
			</template>
			<template #[`header.${filteredHeaders[0].value}`]>
				<v-btn
					v-if="failedPartial(tabKey)?.length > 0"
					text
					class="primary--text"
					fab
					@click="failDialog = true"
					small>
					<v-icon>mdi-refresh-circle</v-icon>
				</v-btn>
				<span>
					{{ filteredHeaders[0].text }}
				</span>
			</template>
			<template
				v-for="(_, name) of $scopedSlots"
				#[name]="slotProps">
				<slot
					v-bind="slotProps"
					:name="name"
					:extraData="extraData"></slot>
			</template>
			<template #[`item.actions`]="{ item }">
				<v-tooltip bottom>
					<template #activator="{ on: tooltip }">
						<v-btn
							v-on="tooltip"
							fab
							x-small
							text
							outlined
							class="mx-2"
							color="primary"
							@click.stop.prevent="$emit('addToCompare', item, filter)">
							<v-icon>mdi-compare-horizontal</v-icon>
						</v-btn>
					</template>
					<span>Add to comparison</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-expansion-panels
			flat
			class="ma-0 pa-0"
			:style="`z-index: ${fullScreen ? 200 : 1}`"
			:value="0"
			multiple
			v-model="_showGraph">
			<v-expansion-panel class="ma-0 pa-0">
				<v-expansion-panel-header
					class="my-0 py-0 my-4"
					style="min-height: 40px"
					ripple
					:color="`menu_background ${$vuetify.theme.dark ? '' : 'lighten-1'}`">
					<b>Graph</b>
				</v-expansion-panel-header>
				<v-expansion-panel-content class="ma-0 pa-0">
					<div class="d-flex flex-row justify-space-between align-center">
						<v-sheet
							:class="{ sfscreenSelector: fullScreen }"
							:width="enabledGraphs.length * 45"
							class="pa-1"
							rounded
							:color="`${fullScreen ? 'transparent' : 'secondary'}`">
							<div
								class="d-flex flex-row sliderBitch"
								:style="`background-position: ${sliderPos}; background-size: ${sliderSize}`">
								<div
									v-for="el in enabledGraphs"
									:key="el"
									class="px-2 py-1"
									style="border-radius: 5px">
									<v-icon
										class="noHover"
										:color="`${
											graphType == el ? 'kajot-anti-text' : 'kajot-text'
										}`"
										@click="(e) => switchGraph(el)">
										mdi-{{ el }}
									</v-icon>
								</div>
							</div>
						</v-sheet>
						<div
							:class="{ sfscreenOff: fullScreen }"
							class="d-flex flex-row justify-end">
							<v-icon
								@click="downloadGraph"
								class="noHover mr-1"
								color="kajot-text">
								mdi-download
							</v-icon>
							<a
								ref="exportLink"
								:download="`graph.png`"
								:href="graphUrl"
								hidden></a>
							<v-icon
								@click="fullScreen = !fullScreen"
								class="noHover"
								color="kajot-text">
								mdi-fullscreen{{ fullScreen ? "-exit" : "" }}
							</v-icon>
						</div>
					</div>
					<div class="w100 d-flex justify-center mb-4">
						<div class="d-flex flex-row flex-wrap justify-center mt-1">
							<template
								v-for="item in pieLegend.filter(
									(el) =>
										!disabledFields.includes(
											filteredHeaders.find((fh) => fh.text === el.text).value
										)
								)">
								<v-chip
									small
									@click="(e) => legendClick(e, item)"
									class="k-reset noTouch mr-1 mt-1"
									:color="`${
										item.hidden || (isSpecial && selectedLine !== item.value)
											? 'gray'
											: item.fillStyle
									}`"
									:key="item.text">
									{{ item.text }}
								</v-chip>
							</template>
						</div>
					</div>
					<v-overlay
						:value="fullScreen"
						persistent
						color="nav_background"
						opacity="0.7"
						style="z-index: 900"></v-overlay>
					<div
						style="height: 85vh"
						:style="`position: ${fullScreen ? 'fixed' : 'sticky'}`"
						:class="[{ sfscreen: fullScreen }, 'd-flex justify-center']"
						class="sf">
						<Component
							ref="graph"
							v-if="!getDrawerLoading && data?.length > 0"
							:is="graphType"
							:data="graphType == 'chart-pie' ? pieData : chartData"
							:options="graphType == 'chart-pie' ? pieOptions : chartOptions"
							:plugins="chartPlugins"></Component>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-dialog
			max-width="500px"
			v-model="failDialog">
			<v-card dense>
				<v-card-title
					class="primary kajot-anti-text--text d-flex align-center justify-center pa-2"
					style="font-weight: 500">
					Data loading failed
				</v-card-title>
				<FailedItems
					v-model="retry"
					:casinoList="casinoList"
					:unfinished="failedPartial(this.tabKey)"></FailedItems>
				<v-card-actions>
					<v-btn
						@click="failDialog = false"
						text
						color="primary">
						Close
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						:disabled="retry.length === 0"
						text
						color="primary"
						@click="onRetry">
						Retry
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import Table from "../../mixins/table";
import { mapGetters } from "vuex";
import { StatisticsConverter } from "../../constants/converters/statisticsConverter";
import { isMobile } from "../../constants/helpers";
import {
	Bar as chartBar,
	Line as chartLine,
	Pie as chartPie,
} from "vue-chartjs";
import { Chart as ChartJS } from "chart.js";
import { watch } from "vue";
import FailedItems from "../shared/FailedItems.vue";

export default {
	props: {
		allowedCasinos: [],
		_options: {
			type: Object,
			default: () => ({}),
		},
		tabKey: {
			type: String,
			default: "rootTab",
		},
		aggregator: {
			type: String,
		},
		showGraph: {
			type: Array,
			default: () => [],
		},
		disabledFields: {
			type: Array,
			default: () => [],
		},
		headers: {
			type: Array,
			default: () => [],
		},
		loadList: {
			type: Function,
		},
		graphLabels: {
			type: Object,
		},
		graphType: {
			type: String,
			default: "chart-bar",
		},
		dataType: String,
		loadParams: Object,
		scope: String,
		filter: Object,
		level: Number,
	},
	components: {
		chartBar,
		chartLine,
		chartPie,
		FailedItems,
	},
	mixins: [Table],
	data() {
		return {
			key: 0,
			type: "undefined",

			failDialog: false,
			retry: [],

			data: [],
			extraData: [],

			loaded: false,
			noOptions: true,
			globalize: StatisticsConverter.globalize,
			pieLegend: [],
			chartInstance: undefined,
			graphUrl: undefined,
			fullScreen: false,
			enabledGraphs: ["chart-bar", "chart-line", "chart-pie"],
			selectedLine: "sum_bet",
		};
	},
	computed: {
		isMobile,
		progress() {
			return (
				(this.progressPartial(this.tabKey).done /
					this.progressPartial(this.tabKey).full) *
				100
			);
		},
		loadingText() {
			if (
				!this.progressPartial(this.tabKey).done ||
				!this.progressPartial(this.tabKey).full
			)
				return "Loading data...";
			return `Loading data for casinos - ${
				this.progressPartial(this.tabKey).done
			} of ${this.progressPartial(this.tabKey).full}`;
		},
		dataLoading() {
			return this.worker(this.type) ? true : false;
		},

		_showGraph: {
			get() {
				return this.showGraph;
			},
			set(e) {
				this.$emit("toggleGraph", e);
			},
		},
		...mapGetters(["getDrawerLoading", "userPerm", "casinoList"]),
		...mapGetters("statistics2", {
			dataPartial: "dataPartial",
			failedPartial: "failedPartial",
			progressPartial: "progressPartial",
			worker: "worker",
		}),
		rowIsClickable() {
			return this.level < 3;
		},
		isSpecial() {
			if (
				this.graphType == "chart-line" &&
				this.level === 1 &&
				this.filter?.casinos?.length > 1
			)
				return true;
			return false;
		},
		dateFormat() {
			switch (this.scope) {
				case "daily":
					return {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						timeZoneName: undefined,
					};
				case "monthly":
					return { month: "long", year: "numeric", timeZoneName: undefined };
				default:
					return {};
			}
		},
		sortedData() {
			if (this.level !== 1) return this.data;
			let a = JSON.parse(JSON.stringify(this.data ?? [])).sort(
				(a, b) => new Date(a.date_from) - new Date(b.date_from)
			);
			a.forEach(
				(el) =>
					(el.date_from = new Date(el.date_from).toLocaleString(
						navigator.language,
						{
							...this.dateFormat,
						}
					))
			);
			return a;
		},
		lineLabels() {
			let dateSet = new Set();
			this.extraData.classic.forEach((item) => dateSet.add(item.date_from));
			return Array.from(dateSet)
				.sort((a, b) => new Date(a) - new Date(b))
				.map((el) =>
					new Date(el).toLocaleString(navigator.language, {
						...this.dateFormat,
					})
				);
		},
		sliderPos() {
			return `${
				this.enabledGraphs.findIndex((el) => el == this.graphType) *
				(100 / (this.enabledGraphs.length - 1))
			}%`;
		},
		sliderSize() {
			return `${100 / this.enabledGraphs.length}%`;
		},
		chartOptions() {
			return {
				interaction: {
					intersect: true,
					mode: "nearest",
					position: "nearest",
					axis: "xy",
				},
				minBarLength: 3,
				plugins: {
					tooltip: {
						callbacks: {
							label: (context) => {
								let label = context.dataset.label || "";

								if (this.isSpecial) {
									label = this.allowedCasinos.find(
										(el) => el.casino_id == context.dataset.label
									).website;
								}

								if (label) {
									label += ": ";
								}
								if (context.parsed.y == null) {
									return label;
								}
								let opts = {
									style: "currency",
									currency: context.raw.currency ?? "EUR",
								};
								if (context.dataset.yAxisID === "rtp") {
									opts = { style: "percent" };
								}
								if (context.dataset.yAxisID === "rounds_count") {
									opts = { style: "decimal" };
								}

								return (
									label +
									Intl.NumberFormat("en", {
										notation: "compact",
										...opts,
									}).format(context.parsed.y)
								);
							},
						},
					},
					legend: {
						display: false,
						labels: {
							generateLabels: (graph) => {
								if (
									graph.config.type === "line" &&
									this.level === 1 &&
									this.filter?.casinos?.length > 1
								) {
									return this.mrdkaJebka(graph);
								}
								return ChartJS.defaults.plugins.legend.labels.generateLabels(
									graph
								);
							},
						},
					},
				},
				parsing: {
					xAxisKey: this.filteredHeaders?.[0]?.value,
					rtpKey: "rtp",
					countKey: "rounds_count",
				},
				scales: {
					x: {
						stacked: true,
						display: true,
						labels: this.isSpecial ? this?.lineLabels : undefined,
					},
					y: {
						display: this.isMobile ? false : true,
						ticks: {
							// Include a dollar sign in the ticks
							callback: function (val) {
								return Intl.NumberFormat("en", {
									currency: val?.raw?.currency ?? "EUR",
									style: "currency",
									notation: "compact",
								}).format(val);
							},
						},
					},
					rtp: {
						display: this.isMobile ? false : "auto",
						type: "linear",
						position: "right",
						beginAtZero: true,
						grid: {
							display: true,
							drawTicks: false,
						},
						ticks: {
							callback: function (val) {
								return val >= 0
									? Intl.NumberFormat("en", {
											notation: "compact",
											style: "percent",
									  }).format(val)
									: undefined;
							},
						},
					},
					rounds_count: {
						ticks: {
							callback: function (val) {
								return val >= 0
									? Intl.NumberFormat("en", { notation: "compact" }).format(val)
									: "";
							},
							color: "white",
						},
						display: this.isMobile ? false : "auto",
						type: "linear",
						position: "right",
					},
				},
				maintainAspectRatio: false,
				responsive: true,
			};
		},
		chartPlugins() {
			return [
				{
					afterUpdate: (chart) => {
						this.chartInstance = chart;
						this.pieLegend =
							chart.options.plugins.legend.labels.generateLabels(chart);
					},
					afterDataLimits: (a) => {
						if (!a.scales.y.ticks) return;

						const scale = Math.abs(a.scales.y.min) / Math.abs(a.scales.y.max);
						//TODO: check validity of +1
						const tickCount = a.scales.y.ticks?.length - 1;

						// center roumd_coumt axis vertically
						a.scales.rounds_count.min = -(a.scales.rounds_count.max * scale);
						//set axis color by graph legend color
						a.options.scales.rounds_count.ticks.color =
							a.data.datasets.find((el) => el.yAxisID === "rounds_count")
								?.borderColor ?? "white";

						a.options.scales.rounds_count.ticks.stepSize =
							(Math.abs(a.scales.rounds_count.max) +
								Math.abs(a.scales.rounds_count.min)) /
							tickCount;

						a.scales.rtp.min = -(a.scales.rtp.max * scale);
						a.options.scales.rtp.ticks.color =
							a.data.datasets.find((el) => el.yAxisID === "rtp")?.borderColor ??
							"white";
						a.options.scales.rtp.ticks.stepSize =
							(Math.abs(a.scales.rtp.max) + Math.abs(a.scales.rtp.min)) /
							tickCount;
					},
				},
			];
		},
		chartData() {
			if (this.isSpecial) {
				const res = Object.keys(this.extraData.per_casino).reduce(
					(agg, curr, idx) => {
						agg.datasets.push({
							data: this.extraData.per_casino[curr].total.map((el) => {
								return {
									...el,
									...{
										date_from: new Date(el.date_from).toLocaleString(
											navigator.language,
											{
												...this.dateFormat,
											}
										),
									},
								};
							}),
							hidden:
								this.graphLabels?.[`${this.graphType}`]?.find(
									(el) => el.name === curr
								)?.hidden ?? false,
							label: curr,
							parsing: { yAxisKey: this.selectedLine },
						});
						return agg;
					},
					{ datasets: [] }
				);
				return res;
			} else {
				const res = [...this.filteredHeaders]
					.sort((a, b) => {
						if (a.value === "rtp" || a.value === "rounds_count") {
							return 1;
						} else if (b.value !== "rtp" || b.value !== "rounds_count")
							return -1;
						return 0;
					})
					.reduce(
						(agg, curr, idx) => {
							if (curr.isData) {
								agg.datasets.push({
									type:
										curr.value === "rtp" &&
										this.sortedData.filter((e) => e.name !== "All casinos")
											.length > 1
											? "line"
											: undefined,
									data: [
										...this.sortedData.filter((e) => e.name !== "All casinos"),
									],
									label: curr.text,
									hidden:
										(this.graphLabels?.[`${this.graphType}`]?.find(
											(el) => el.name == curr.text
										).hidden ||
											this.disabledFields.includes(curr.value)) ??
										false,
									stack: idx,
									yAxisID:
										curr.value === "rtp" || curr.value === "rounds_count"
											? curr.value
											: undefined,
									parsing: { yAxisKey: curr.value },
								});
							}
							return agg;
						},
						{ datasets: [] }
					);
				return res;
			}
		},

		pieOptions() {
			return {
				plugins: {
					tooltip: {
						callbacks: {
							//HERE
							label: (context) => {
								let label = context.dataset.parsing.name ?? "";

								if (label) {
									label += ": ";
								}
								if (context.parsed == null) {
									return label;
								}
								let opts = {
									style: "currency",
									currency: context.raw.currency ?? "EUR",
								};
								if (context.dataset.parsing?.key === "rtp") {
									opts = { style: "percent" };
								}
								if (context.dataset.parsing?.key === "rounds_count") {
									opts = { style: "decimal" };
								}

								return (
									label +
									Intl.NumberFormat("en", {
										notation: "compact",
										...opts,
									}).format(context.parsed)
								);
							},
						},
					},
					legend: {
						display: false,
						labels: {
							boxWidth: 0,
							generateLabels: (graf) => this.kundaMrdka(graf),
						},
					},
					colors: {
						forceOverride: true,
					},
				},
				responsive: true,
			};
		},
		pieData() {
			return {
				labels: this.sortedData
					.filter((e) => e.name !== "All casinos")
					.map((el) => el[this.filteredHeaders[0].value]),
				...this.filteredHeaders.reduce(
					(agg, curr, idx) => {
						if (curr.isData)
							agg.datasets.push({
								data: this.sortedData.filter((e) => e.name !== "All casinos"),
								hidden:
									(this.graphLabels?.[`${this.graphType}`]?.find(
										(el) => el.name === curr.text
									)?.hidden ||
										this.disabledFields.includes(curr.value)) ??
									idx != 2,
								borderWidth: 0,
								parsing: { key: curr.value, name: curr.text },
							});
						return agg;
					},
					{ datasets: [] }
				),
			};
		},
		__options: {
			get() {
				return this._options;
			},
			set(val) {
				this.$emit("update:_options", val);
			},
		},
	},
	methods: {
		async onRetry() {
			this.failDialog = false;
			this._loadTable(true);
		},
		keySwitcher() {
			this.key = this.key++ % 2;
		},
		async loadData(force = false) {
			if (this.loaded && !force) return;
			await this._loadTable(false, force);
			this.loaded = true;
		},
		async legendClick(e, item) {
			const instance = this.chartInstance;
			if (instance.config.type === "pie") {
				await this.dalsiaMrdka(e, item, instance.legend);
			} else if (
				instance.config.type == "line" &&
				this.level === 1 &&
				this.filter?.casinos?.length > 1
			) {
				this.picovina(e, item, instance.legend);
			} else {
				ChartJS.defaults.plugins.legend.onClick(e, item, instance.legend);
				await instance.update();
				await this.$nextTick();
				const tmpLabels = {
					[`${this.graphType}`]: this.pieLegend.map((el) => {
						return {
							name: el.text,
							hidden: el.hidden,
						};
					}),
				};

				const tmpLabelsFull = { ...this.graphLabels, ...tmpLabels };
				this.$emit("update:graphLabels", tmpLabelsFull);
			}
		},
		async downloadGraph() {
			this.graphUrl = this.$refs.graph.$el.toDataURL();
			await this.$nextTick();
			this.$refs.exportLink.click();
		},
		picovina(_, item) {
			const val = item.value;
			this.selectedLine = val;
		},
		async dalsiaMrdka(e, item, leg) {
			const index = item.datasetIndex;
			var instance = leg.chart;
			instance.data.datasets.map((el, idx) =>
				idx == index ? (el.hidden = false) : (el.hidden = true)
			);
			instance.data.datasets.map((el, idx) =>
				idx == index ? (el.hidden = false) : (el.hidden = true)
			);
			const tmpLabels = {
				[`${this.graphType}`]: instance.data.datasets.map((el) => {
					return {
						name: el.parsing.name,
						hidden: el.hidden,
					};
				}),
			};

			const tmpLabelsFull = { ...this.graphLabels, ...tmpLabels };
			this.$emit("update:graphLabels", tmpLabelsFull);
			instance.update();
		},
		mrdkaJebka(graph) {
			return this.filteredHeaders
				.filter((el) =>
					[
						"sum_bet",
						"sum_win",
						"netto",
						"rtp",
						"avg_bet",
						"rounds_count",
					].includes(el.value)
				)
				.map((el, idx) => {
					return {
						text: el.text,
						value: el.value,
						// Border radius of the legend item.
						// Introduced in 3.1.0
						borderRadius: 5,
						// Index of the associated dataset
						datasetIndex: idx,
						// Fill style of the legend box
						fillStyle: "primary darken-3",
						// Text color
						fontColor:
							el.value === this.selectedLine
								? "#36a9e3"
								: this.$vuetify.theme.dark
								? "white"
								: "black",
						// If true, this item represents a hidden dataset. Label will be rendered with a strike-through effect
						hidden: false,
						// For box border. See https://developer.mozilla.org/en/docs/Web/API/CanvasRenderingContext2D/lineCap
						lineCap: "circle",
						// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
						lineDash: undefined,
						// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
						lineDashOffset: undefined,
						// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
						lineJoin: "circle",
						// Width of box border
						lineWidth: 1,
						// Stroke style of the legend box
						strokeStyle: "transparent",
						// Point style of the legend box (only used if usePointStyle is true)
						pointStyle: undefined,
						// Rotation of the point in degrees (only used if usePointStyle is true)
						rotation: 0,
					};
				});
		},
		kundaMrdka(he) {
			const data = he.data.datasets;
			const singleSet = he.data.datasets[0].data.length == 1;
			return data.map((el, idx) => {
				// Create rgba value from rgb
				const res = he.data.datasets?.[idx]?.backgroundColor[0]
					?.replace("rgb", "rgba")
					?.replace(")", ",0.5)");
				return {
					text: data[idx].parsing.name,
					// Border radius of the legend item.
					// Introduced in 3.1.0
					borderRadius: 5,
					// Index of the associated dataset
					datasetIndex: idx,
					// Fill style of the legend box
					fillStyle: singleSet ? res : "primary darken-3",
					// Text color
					fontColor: this.$vuetify.theme.dark ? "white" : "black",
					// If true, this item represents a hidden dataset. Label will be rendered with a strike-through effect
					hidden: data[idx].hidden,
					// For box border. See https://developer.mozilla.org/en/docs/Web/API/CanvasRenderingContext2D/lineCap
					lineCap: "circle",
					// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
					lineDash: undefined,
					// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
					lineDashOffset: undefined,
					// For box border. See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineJoin
					lineJoin: "circle",
					// Width of box border
					lineWidth: 1,
					// Stroke style of the legend box
					strokeStyle: "transparent",
					// Point style of the legend box (only used if usePointStyle is true)
					pointStyle: undefined,
					// Rotation of the point in degrees (only used if usePointStyle is true)
					rotation: 0,
				};
			});
		},
		async switchGraph(el) {
			this.$emit("update:graphType", el);
		},
		itemClass() {
			return `table-item${this.rowIsClickable ? " table-item--clickable" : ""}`;
		},
		async _loadTable(retryFlag = false, force = false) {
			this.loaded = false;
			if (!force && this.loadParams && this.loadParams.selectedTab != 0) return;
			this.type = await this.loadList(
				this.loadParams ?? this.filter,
				retryFlag ? this.retry : undefined
			);
		},
	},
	watch: {
		dataLoading: {
			handler(e, prev) {
				this.$emit("loading", e);
				if (!prev && e) {
					this.data = [];
					this.extraData = [];
				}
				if (prev && !e) {
					if (
						this.failedPartial(this.tabKey) &&
						this.failedPartial(this.tabKey).length > 0
					) {
						this.failDialog = true;
					}

					const temp = this.dataPartial(
						this.userPerm,
						this.tabKey,
						this.scope,
						this.aggregator
					);

					if (!temp || temp?.length === 0) {
						this.data = [];
						this.extraData = [];
						return;
					}

					if (this.level === 0) {
						this.extraData = temp.aggregated.total;
						this.data = [
							{
								name: "All casinos",
								id: "all",
								...temp.global.total,
								data: {
									...temp?.aggregated,
									per_casino: temp.per_casino,
									global: temp.global,
								},
							},
							...Object.entries(temp?.per_casino ?? {}).map(([id, data]) => ({
								name: this.allowedCasinos?.find(
									(el) => el.casino_id === parseInt(id)
								)?.website,
								id: parseInt(id),
								data,
								...data.global.total,
							})),
						];
						return;
					}
					this.data = temp.aggregated.total;
					this.extraData = temp.aggregated;
				}
			},
			immediate: true,
		},
		loadParams() {
			this._loadTable();
		},
	},
};
</script>

<style scoped>
::v-deep .v-data-table__progress > .column {
	padding: 0 0 !important;
}

.sliderBitch {
	position: relative;
	border-radius: 5px;
	background-image: linear-gradient(
		var(--v-primary-base),
		var(--v-primary-base)
	);
	transition: background-position 0.35s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.sfscreen {
	background-color: var(--v-background-base);
	position: fixed;
	z-index: 900;
	top: calc(50% - 100vh / 2);
	left: calc(50% - 100vw / 2);
	padding: 20px;
	width: 100vw !important;
	height: 100vh !important;
	animation: expandAnimation 0.3s ease-out;
	transform-origin: center center;
}

.sfscreenSelector {
	position: fixed;
	z-index: 901;
	top: 1%;
	left: 4%;
	animation: expandAnimation 0.8s ease-in;
	transform-origin: center center;
}

.sfscreenOff {
	position: fixed;
	z-index: 901;
	top: 18px;
	right: 2.5%;
	animation: expandAnimation 0.8s ease-in;
	transform-origin: center center;
}

@keyframes expandAnimation {
	from {
		scale: 0;
	}
	to {
		scale: 1;
	}
}

.noHover::after {
	opacity: 0;
	background-color: transparent;
}
</style>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
.sliderBitch {
	position: relative;
	border-radius: 5px;
	background-image: linear-gradient(
		var(--v-primary-base),
		var(--v-primary-base)
	);
	transition: background-position 0.35s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.sfscreen {
	background-color: var(--v-background-base);
	position: absolute;
	z-index: 900;
	top: calc(50% - 100vh / 2);
	left: calc(50% - 100vw / 2);
	padding: 20px;
	width: 100vw !important;
	height: 100vh !important;
	animation: expandAnimation 0.3s ease-out;
	transform-origin: center center;
}

.sfscreenSelector {
	position: fixed;
	z-index: 901;
	top: 1%;
	left: 4%;
	animation: expandAnimation 0.8s ease-in;
	transform-origin: center center;
}

.sfscreenOff {
	position: fixed;
	z-index: 901;
	top: 18px;
	right: 2.5%;
	animation: expandAnimation 0.8s ease-in;
	transform-origin: center center;
}

.noTouch {
	user-select: none;
}

@keyframes expandAnimation {
	from {
		scale: 0;
	}
	to {
		scale: 1;
	}
}

.noHover::after {
	opacity: 0;
	background-color: transparent;
}
</style>
